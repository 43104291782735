var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mile-stone",
      class: ["arrow", "log"].includes(_vm.type) ? "" : "mile-stone79",
    },
    [
      _vm.maxscrollLeft > 0
        ? _c(
            "div",
            {
              class:
                _vm.type == "arrow" ? "field46-btn-box8" : "field46-btn-box79",
            },
            [
              _c("en-icon", {
                staticClass: "field46-btn",
                staticStyle: { "margin-right": "10px" },
                attrs: { name: "tongjitu-tingzhi-copy", size: 10 },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clickScroll("pre")
                  },
                },
              }),
              _c("en-icon", {
                staticClass: "field46-btn",
                attrs: { name: "tongjitu-tingzhi2", size: 10 },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clickScroll("next")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "solid"
        ? _c(
            "div",
            { ref: "box", staticClass: "milepost-box style1" },
            _vm._l(_vm.list, function (node, index) {
              return _c(
                "div",
                {
                  key: "mun1" + index,
                  ref: "style1" + index,
                  refInFor: true,
                  staticClass: "milepost-item milepost-flg",
                  class: {
                    now: _vm.highlightIndex === index,
                    finish: index < _vm.highlightIndex,
                  },
                },
                [
                  _c("div", { staticClass: "milepost-conter" }, [
                    _c("div", { staticClass: "style1-top" }, [
                      _c("div", {
                        class: index == 0 ? "" : "style1-line style1-line-felt",
                      }),
                      _c("div", { staticClass: "style1-0" }),
                      _c("div", {
                        class:
                          index == _vm.list.length - 1 ? "" : "style1-line",
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "style1-txt",
                        attrs: { title: node || "" },
                      },
                      [_vm._v(_vm._s(node || ""))]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm.type === "dash"
        ? _c(
            "div",
            { ref: "box", staticClass: "milepost-box style3" },
            [
              _vm._l(_vm.list, function (node, index) {
                return _c(
                  "div",
                  {
                    key: "mun2" + index,
                    ref: "style3" + index,
                    refInFor: true,
                    staticClass: "milepost-item milepost-flg",
                    class: {
                      now: _vm.highlightIndex === index,
                      finish: index < _vm.highlightIndex,
                    },
                  },
                  [
                    _c("div", { staticClass: "milepost-conter" }, [
                      _c("div", { staticClass: "style3-num" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c("span", { attrs: { title: node || "" } }, [
                        _vm._v(_vm._s(node || "")),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._l(_vm.list, function (node, index) {
                return _c(
                  "div",
                  {
                    key: "mun3" + index,
                    ref: "style3tail" + index,
                    refInFor: true,
                    staticClass: "tail",
                    class: {
                      now: _vm.highlightIndex === index,
                      finish: index < _vm.highlightIndex,
                      hide: index + 1 == _vm.list.length,
                    },
                  },
                  [_c("div", { staticClass: "tail-con" })]
                )
              }),
            ],
            2
          )
        : _vm.type === "arrow"
        ? _c(
            "div",
            {
              ref: "box",
              staticClass: "arrow-stone",
              staticStyle: { padding: "0 2px" },
            },
            _vm._l(_vm.list, function (node, index) {
              return _c("stoneArrow", {
                key: "mun4" + index,
                ref: "style2",
                refInFor: true,
                staticClass: "stone-content",
                attrs: {
                  type: index === 0 ? 1 : 2,
                  name: node,
                  colorType: _vm.colorTypeWithIndex(index),
                },
              })
            }),
            1
          )
        : _vm.type === "log"
        ? _c(
            "div",
            { ref: "box", staticStyle: { padding: "0 2px" } },
            [_c("stoneLog", { attrs: { logInfo: _vm.logInfo } })],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }