<!--
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020/12/29 14:03
 -->
<template>
  <div class="stone-arrow"
       :style="{'background-color':fillColor}">
    <canvas id="left" width="12" height="50" class="left" ref="left" v-if="type === 2"></canvas>
    <div class="middle"
         :style="{'color':textColor}"
         :class="{
                'need-border':colorType === 1,
                'need-radius':type === 1,
                 }">
      {{name}}
    </div>
    <canvas id="right" width="12" height="50" class="right" ref="right"></canvas>
  </div>
</template>

<script>
export default {
  name: "stoneArrow",
  props: {
    // 箭头类型, 1为右侧箭头, 2双侧箭头
    type: Number,
    colorType: Number, // 1普通颜色,画边框, 2高亮 3半亮
    name: String
  },
  watch: {
    colorType() {
      this.draw();
    }
  },
  computed: {
    fillColor() {
      switch (this.colorType) {
        case 1:
          return "#F6FAFD";
        case 2:
          return "#179EDA";
        case 3:
          return "#179EDA80";
        default:
          return "";
      }
    },
    textColor() {
      switch (this.colorType) {
        case 1:
          return "#333333";
        case 3:
        case 2:
          return "white";
        default:
          return "";
      }
    }
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      const leftCanvas = this.$refs.left;
      if (leftCanvas && leftCanvas.getContext) {
        leftCanvas.style.width = "12px";
        leftCanvas.style.height = "50px";
        leftCanvas.width = 48;
        leftCanvas.height = 200;
        const ctx = leftCanvas.getContext("2d");
        ctx.scale(4, 4);
        ctx.lineWidth = 2;
        ctx.clearRect(0, 0, 12, 50);
        if (this.type === 2) {
          ctx.fillStyle = "white";
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(12, 25);
          ctx.lineTo(0, 50);
          ctx.fill();
        }
        if (this.colorType === 1 && this.type === 2) {
          ctx.strokeStyle = "#DCE5EC";
          ctx.beginPath();
          ctx.moveTo(12, 0);
          ctx.lineTo(0, 0);
          ctx.lineTo(12, 25);
          ctx.lineTo(0, 50);
          ctx.lineTo(12, 50);
          ctx.stroke();
        }
      }
      const rightCanvas = this.$refs.right;
      if (rightCanvas && rightCanvas.getContext) {
        rightCanvas.style.width = "12px";
        rightCanvas.style.height = "50px";
        rightCanvas.width = 48;
        rightCanvas.height = 200;
        const ctx = rightCanvas.getContext("2d");
        ctx.scale(4, 4);
        ctx.clearRect(0, 0, 12, 50);
        ctx.fillStyle = this.fillColor;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(12, 25);
        ctx.lineTo(0, 50);
        ctx.fill();
        if (this.colorType === 1) {
          ctx.strokeStyle = "#DCE5EC";
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(12, 25);
          ctx.lineTo(0, 50);
          ctx.stroke();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .stone-arrow {
    line-height: 50px;

    display: flex;
    .left {
    }
    .right {
      background-color: white;
    }
    .middle {
      flex: 1;
      &.need-border {
        border-bottom: 1px solid #DCE5EC;
        border-top: 1px solid #DCE5EC;
      }
      &.need-radius {
        border-radius: 4px 0px 0px 4px;
      }
      &.need-radius.need-border {
        border-left: 1px solid #DCE5EC;
      }
    }
  }

</style>
