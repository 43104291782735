<!--
 * @Description: 里程碑控件
 * @Author: 梁平贤
 * @Date: 2020/12/28 11:51
 -->
<template>
  <div class="mile-stone-column">
    <el-collapse @change="changeContent" v-model="showContent">
      <el-collapse-item name="1" >
        <template slot="title">
        <div class="header">
          <!-- 占行控件样式 -->
          <en-icon  name="iconguanlianfangan" size="18px" style="margin-right:5px;color:#46D48A" ></en-icon>
          <div class="header-title">里程碑</div>
          <en-icon  name="iconzhankai" color="#A9B5C6" class="drop-down" :style="{transform: showContent.length === 0 ? 'rotate(270deg)':'rotate(0deg)'}" ></en-icon>
          </div>
        </template>
        <stone :logInfo="logNodes" :list="nodes" :highlight-index="currentSelectNodeIndex" :type="stoneType"></stone>
  </el-collapse-item>
  </el-collapse>
  </div>

</template>

<script>
import stone from "./stone/stone";

export default {
  name: "MileStone",
  components: { stone },
  computed: {
    // 类型
    stoneType() {
      switch (Number(this.column.showStyle)) {
        case 7:
          return "solid";
        case 8:
          return "arrow";
        case 9:
          return "dash";
        case 10:
          return "log";
        default:
          return "solid";
      }
    },
    // 关联的单选
    targetColumn() {
      return this.column.findMainAreaField(this.column.targetField);
    },
    // 阶段展示数据
    nodes() {
      return this.column.showContent.map((val) => val.nodeName);
    },
    // 日志流需要数据
    logNodes() {
      return {
        field: this.column.field,
        showSet: this.column.showSet,
        showProgressValue: this.column.showProgressValue,
        progressValue: 0,
        showAscDesc: this.column.showAscDesc,
        nodeName: this.column.name,
        showContent: this.column.showContent
      };
    },
    // 选中的节点indexs
    currentSelectNodeIndex() {
      if (!this.targetColumn.columnValue) {
        return -1;
      }
      const dataSource = Array.isArray(this.targetColumn.columnValue) ? this.targetColumn.columnValue[0] : this.targetColumn.columnValue;
      const selectId = dataSource.id;
      let selectIndex = -1;
      this.column.showContent.some((val, index) => {
        // 条件是多选,只要多选里面
        const exist = val.nodeCondition.some((con) => selectId === con.id);
        if (exist) {
          selectIndex = index;
        }
        return exist;
      });
      return selectIndex;
    }
  },
  data() {
    return {
      showContent: ["1"]
    };
  },
  methods: {
    changeContent(e) {
      this.showContent = e;
    }
  },
  // eslint-disable-next-line vue/return-in-computed-property
  mounted() {
      console.log(this.column, "------");
      console.log(this.businessData, "------");
    },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-collapse {
    // border-top: none;
    border-bottom: none;
  }

/deep/.el-collapse-item__header .el-icon-arrow-right{
        display: none !important;
    }
.mile-stone-column{
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 0;
    .header-title {
      color: #1A1C1E;
      font-size:14px;
      font-weight:bold;
    }
    .drop-down{
      margin-left: 5px;
      width:12px;
      height:12px;
    }
  }
}
</style>
