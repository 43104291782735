<!--
 * @Description:
 * @Author: azh
 * @Date: 2023/09/12 13:04
 -->
 <template>
  <div class="stone-log">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item  name="1">
        <template slot="title">
          <div class="header">
            <span class="node-name">{{ info.nodeName }}</span>
            <span v-if="info.showProgressValue" class="progress">{{ info.progressValue.toFixed(2) }}%</span>
          </div>

        </template>
        <div class="stone-log-main" v-if="showList.length">
          <div  class="stone-log-main-list" v-for="(item,index) in showList" :key="index">
            <div class="stone-log-main-list-itemleft">
              <div class="stone-log-main-list-itemleft-item">
                <div class="line" :class="{'firstbox':index===0}"></div>
                <div v-if="item.nodeState === '1'" class="select-ing">
                  <div class="dian"></div>
                </div>
                <div v-if="item.nodeState === '2'" class="select-voer"></div>
                <div v-if="item.nodeState === '0'" class="select-no"></div>
                <div class="line" :class="{'lastbox': index === showList.length - 1}"></div>
              </div>
            </div>

            <div class="stone-log-main-list-itemrbox">
              <div class="stone-log-main-list-itemrbox-item">
                <div class="title">{{ item.nodeName }}</div>
                <div :class="{'time-ing':item.nodeState === '1','time-over':item.nodeState === '2','time-no':item.nodeState === '0'}">{{ item.nodeTimeFormat }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { businessService } from "@/api/businessModel";

export default {
  name: "stoneLog",
  data() {
    return {
      activeName: "1",
      info: {},
      showList: []
    };
  },
  props: {
    logInfo: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
  },
  computed: {
  },
  async mounted() {
    await this.init();
    this.nodeDispaly();
    this.computeProgress();
  },
  methods: {
    /*
    * @Description:  初始化，通过接口，获取对应节点详情
    */
    async init() {
        this.info = JSON.parse(JSON.stringify(this.logInfo));
        const res = await businessService.detail(this.$route.query);
        if (res?.mdData[this.info.field]?.showContent) {
          this.info.showContent = res?.mdData[this.info.field]?.showContent;
          this.showList = res?.mdData[this.info.field]?.showContent;
        } else {
          this.showList = this.info.showContent;
        }
    },
    /*
    * @Description:  判断 节点是否 展示
    */
    nodeDispaly() {
      if (parseInt(this.info.showSet, 10) === 0) {
        this.showList = this.info.showContent.filter((it) => parseInt(it.nodeState, 0) !== 0);
      } else {
        this.showList = this.info.showContent;
      }
    },
    /*
    * @Description:  计算百分比
    */
    computeProgress() {
      const len = this.info.showContent.length;
      const nodeLength = this.info.showContent.filter((it) => parseInt(it.nodeState, 0) === 2).length;
      this.info.progressValue = parseFloat(nodeLength / len) * 100;
    },
    /*
    * @Description:  节点是否 倒叙
    */
    listReverse() {
        this.showList = this.info.showAscDesc === 0 ? this.showList.reverse() : this.showList;
    }
  }
};
</script>

<style scoped lang="scss">
.stone-log{
  width: 100%;
  padding: 0px 10px 30px 10px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  background: #f4f6fc;
  .header{
    display: flex;
    align-items: center;
    .node-name{
      font-size: 18px;
      color: #000;
      font-weight: 500;
      margin-right: 15px;
    }
    .progress{
      width: 100px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #409eff;
      border-radius: 30px;
      background-color: #e6f3fc;
    }
  }

  &-main{
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    &-list{
      width:100%;
      display:flex;
      &-itemleft{
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          width: 15px;
         &-item{
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          .select-ing{
          width: 15px;
          height: 15px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border:1px solid #409eff;
          .dian{
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #409eff;
          }
        }
        .select-voer{
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #409eff;
        }
        .select-no{
          width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #999;
        }
        .line{
          width: 1px;
          height: 100%;
          flex: 1;
          background-color: #DCDFE6;
        }
         }

      }
      &-itemrbox{
        margin-left: 15px;
        flex: 1;
        padding: 20px  0 20px 0;
        &-item{
          flex: 1;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          padding: 10px 5px;
          min-height: 60px;
          .title{
            font-weight: 500;
            font-size: 16px;
          }
          .time-ing{
            color: #409eff;
          }
          .time-over{
            color: #000;
          }
          .time-no{
            color: #999;
          }
        }
      }
      .firstbox{
        background:linear-gradient( transparent 30%,#DCDFE6 70%)!important;
      }
      .lastbox{
        background:linear-gradient(#DCDFE6 60% ,transparent 40%)!important;
      }
      // &-itemrbox:last-of-type{
      //   padding: 0;
      // }
    }
  }
  /deep/ .el-collapse-item__header{
    background: #f4f6fc !important;
}
}
</style>
