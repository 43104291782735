var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "stone-arrow",
      style: { "background-color": _vm.fillColor },
    },
    [
      _vm.type === 2
        ? _c("canvas", {
            ref: "left",
            staticClass: "left",
            attrs: { id: "left", width: "12", height: "50" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "middle",
          class: {
            "need-border": _vm.colorType === 1,
            "need-radius": _vm.type === 1,
          },
          style: { color: _vm.textColor },
        },
        [_vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
      _c("canvas", {
        ref: "right",
        staticClass: "right",
        attrs: { id: "right", width: "12", height: "50" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }