<!--
 * @Description:
 * @Author: zhangjiaheng
 * @Date: 2020/12/29 14:17
 -->
<template>
  <div class="mile-stone" :class="['arrow','log'].includes(type) ? '' : 'mile-stone79'">
    <!-- <div v-if="type === 'solid'" class="solid-stone">
      <div class="stone-content"
           v-for="(node, index) in list"
           :key="index"
           :class="{
         'select-stone': highlightIndex === index,
         'pre-stone': index < highlightIndex
         }">
        <div class="content">
          <div class="dot"></div>
          <div class="name" :title="node">{{node}}</div>
        </div>
        <div class="line" v-if="index !== list.length - 1">

        </div>
      </div>
    </div> -->
    <div v-if="maxscrollLeft > 0" :class="type == 'arrow' ? 'field46-btn-box8' : 'field46-btn-box79'">
      <en-icon name="tongjitu-tingzhi-copy" class="field46-btn" style="margin-right: 10px;" :size="10" @click.native.stop="clickScroll('pre')"></en-icon>
      <en-icon name="tongjitu-tingzhi2" class="field46-btn" :size="10" @click.native.stop="clickScroll('next')"></en-icon>
    </div>
    <!-- 线性 7 开始 -->
    <div v-if="type === 'solid'" class="milepost-box style1" ref="box">
      <div
        class="milepost-item  milepost-flg"
        v-for="(node, index) in list"
        :key="'mun1' + index"
        :ref="'style1' + index"
        :class="{
          now: highlightIndex === index,
          finish: index < highlightIndex
        }"
      >
        <div class="milepost-conter ">
          <div class="style1-top ">
            <div :class="index == 0 ? '' : 'style1-line style1-line-felt'"></div>
            <div class="style1-0"></div>
            <div :class="index == list.length - 1 ? '' : 'style1-line'"></div>
          </div>
          <div class="style1-txt" :title="node || ''">{{ node || "" }}</div>
        </div>
      </div>
    </div>
    <!-- 线性 7 结束 -->
    <!-- 虚线 9 开始 -->
    <!-- 生成完成后再排序 -->
    <div v-else-if="type === 'dash'" class="milepost-box style3" ref="box">
      <div
        class="milepost-item  milepost-flg "
        v-for="(node, index) in list"
        :key="'mun2' + index"
        :ref="'style3' + index"
        :class="{
          now: highlightIndex === index,
          finish: index < highlightIndex
        }"
      >
        <div class="milepost-conter  ">
          <div class="style3-num ">{{ index + 1 }}</div>
          <span :title="node || ''">{{ node || "" }}</span>
        </div>
      </div>
      <div
        class="tail "
        v-for="(node, index) in list"
        :key="'mun3' + index"
        :ref="'style3tail' + index"
        :class="{
          now: highlightIndex === index,
          finish: index < highlightIndex,
          hide: index + 1 == list.length
        }"
      >
        <div class="tail-con "></div>
      </div>
    </div>
    <!-- 虚线 9 结束 -->
    <!-- <div v-else-if="type === 'dash'" class="dash-stone">
      <div
        class="stone-content"
        v-for="(node, index) in list"
        :key="index"
        :class="{
          'select-stone': highlightIndex === index,
          'pre-stone': index < highlightIndex
        }"
      >
        <div class="content">
          <div class="dot">{{ index + 1 }}</div>
          <div class="name">{{ node }}</div>
        </div>
        <div class="line" v-if="index !== list.length - 1"></div>
      </div>
    </div> -->
    <!-- 块 -->
    <div v-else-if="type === 'arrow'" class="arrow-stone" ref="box" style="padding: 0 2px;">
      <stoneArrow ref="style2" class="stone-content" v-for="(node, index) in list" :key="'mun4' + index" :type="index === 0 ? 1 : 2" :name="node" :colorType="colorTypeWithIndex(index)"> </stoneArrow>
    </div>
    <!-- 日志 -->
    <div v-else-if="type === 'log'" ref="box" style="padding: 0 2px;">
      <stoneLog :logInfo="logInfo"> </stoneLog>
    </div>
  </div>
</template>

<script>
import stoneArrow from "./stoneArrow";
import stoneLog from "./stoneLog";

export default {
  name: "stone",
  components: { stoneArrow, stoneLog },
  props: {
    highlightIndex: Number,
    list: Array,
    logInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      default: "solid",
      validator: (val) => ["solid", "dash", "arrow", "log"].indexOf(val) !== -1
    }
  },
  data() {
    return {
      // 是否可以滚动
      maxscrollLeft: 0,
      // 滚动的距离
      scrollLeft: 0
    };
  },
  watch: {
    // 虚线的时候需要监听变化
    highlightIndex() {
      this.$nextTick(() => {
        this.maxscrollLeft = this.$refs.box.scrollWidth - this.$refs.box.clientWidth;
        if (this.highlightIndex > -1) {
          if (this.highlightIndex === 0) {
            // 在起点
            this.$refs.box.scrollLeft = 0;
          } else if (this.list.length - 1 === this.highlightIndex) {
            // 最后一个
            this.$refs.box.scrollLeft = this.maxscrollLeft;
          } else {
            // 中间
            let w1 = 0;
            let w2 = 0;
            // 取总宽一半
            const w3 = this.$refs.box.clientWidth / 2;
            if (this.type === "solid") {
              w1 = this.$refs.style10[0].clientWidth;
            } else if (this.type === "dash") {
              w1 = this.$refs.style30[0].clientWidth;
              w2 = this.$refs.style3tail0[0].clientWidth;
            } else if (this.type === "arrow") {
              w1 = this.$refs.style2[0].$el.clientWidth;
            }
            const left = this.highlightIndex * (w1 + w2) + w1 / 2 - w3;
            this.$refs.box.scrollLeft = left;
          }
        }
      });
    }
  },
  mounted() {
    console.log(this.list);
    this.$nextTick(() => {
      this.maxscrollLeft = this.$refs.box.scrollWidth - this.$refs.box.clientWidth;
      if (this.type === "dash") {
        this.sort();
      }
    });
  },
  methods: {
    // 箭头的颜色类型
    colorTypeWithIndex(index) {
      if (index < this.highlightIndex) {
        return 3;
      }
      if (index === this.highlightIndex) {
        return 2;
      }
      return 1;
    },
    // 虚线 排序
    sort() {
      const This = this;
      for (let index = 0; index < this.list.length; index++) {
        This.insertAfter(This.$refs[`style3tail${index}`][0], This.$refs[`style3${index}`][0]);
      }
    },
    // newElement是要追加的元素 targetElement 是指定元素的位置
    insertAfter(newElement, targetElement) {
      // 找到指定元素的父节点
      const parent = targetElement.parentNode;
      // 判断指定元素的是否是节点中的最后一个位置 如果是的话就直接使用appendChild方法
      if (parent.lastChild === targetElement) {
        parent.appendChild(newElement, targetElement);
      } else {
        parent.insertBefore(newElement, targetElement.nextSibling);
      }
    },
    // 点击滑动
    clickScroll(str) {
      // debugger;
      let w = 0;
      this.scrollLeft = this.$refs.box.scrollLeft;
      if (str === "pre") {
        if (this.scrollLeft <= 0) {
          // 在原点 不进行滚动
          return;
        }
        w = this.scrollLeft - (this.$refs.box.offsetWidth - (this.type === "arrow" ? 0 : 122));
        w = w < 0 ? 0 : w;
      } else {
        // 获取最大滚动值
        if (this.scrollLeft >= this.maxscrollLeft) {
          return;
        }
        this.scrollLeft = this.$refs.box.scrollLeft;
        w = this.$refs.box.offsetWidth - (this.type === "arrow" ? 0 : 122) + this.scrollLeft;
        w = w >= this.maxscrollLeft ? this.maxscrollLeft : w;
      }
      this.$refs.box.scrollLeft = w;
    }
  }
};
</script>

<style scoped lang="scss">
.hide {
  display: none !important;
}
.mile-stone {
  cursor: default;
  position: relative;
}
// zjh
.mile-stone79 {
  padding: 0 60px;
  background: #f6fafd;
  border: 1px #f3f3f3 solid;
  border-radius: 5px;
  cursor: default;
}
.field46-btn-box8 {
  position: absolute;
  right: 14px;
  top: 10px;
}
.field46-btn-box79 {
  position: absolute;
  right: 14px;
  top: 10px;
}
.field46-btn {
  color: #a9b5c6;
  font-size: 12px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.solid-stone,
.dash-stone,
.arrow-stone {
  display: flex;
  height: 50px;
  justify-content: space-between;
  scroll-behavior: smooth; //平滑滚动
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .stone-content {
    min-width: 230px;
    text-align: center;
    height: 50px;
  }
}

.solid-stone,
.dash-stone {
  line-height: 50px;
  height: 50px;
  background: #f6fafd;
  border: 1px solid #dce5ec;
  border-radius: 4px;
  padding: 0 75px;
  .stone-content {
    display: flex;
    flex-grow: 1;
  }
  .stone-content:nth-last-child(1) {
    flex-grow: 0;
  }
}

.dash-stone {
  .stone-content {
    padding-top: 18px;
    /*选中效果*/
    &.select-stone {
      .content {
        .dot {
          background: #179eda;
          opacity: 1;
        }
        .name {
          font-weight: bold;
          color: #3e90fe;
          opacity: 1;
        }
      }
    }
    &.pre-stone {
      .content {
        .dot {
          background: #179eda;
          opacity: 0.5;
        }
        .name {
          color: #179eda;
          opacity: 0.5;
        }
      }
      .line {
        border-top: 2px dashed #3e90fe;
        opacity: 0.5;
      }
    }
    .content {
      height: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      .dot {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        background: #d0d8e2;
        color: white;
      }
      .name {
        margin-left: 11px;
        text-align: center;
      }
    }
    .line {
      flex: 1;
      height: 2px;
      border-top: 2px dashed #d9ddeb;
      opacity: 0.5;
      border-radius: 1px;
      margin-top: 6px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
// 线
.solid-stone {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  .stone-content {
    padding-top: 12px;
    // min-width: 130px;
    /*选中效果*/
    &.select-stone {
      .content {
        .dot {
          border: 2px solid #3e90fe;
          opacity: 1;
        }
        .name {
          font-weight: bold;
          color: #3e90fe;
        }
      }
    }
    &.pre-stone {
      .content {
        .dot {
          border: 2px solid #3e90fe;
          opacity: 0.5;
        }
        .name {
          color: #3e90fe;
          opacity: 0.5;
        }
      }
      .line {
        background: #3e90fe;
        opacity: 0.5;
      }
    }
    .content {
      height: 50px;
      line-height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 12px;

      .dot {
        width: 12px;
        height: 12px;
        background: #f6fafd;
        border: 2px solid #a9b5c6;
        opacity: 0.5;
        border-radius: 50%;
      }
      .name {
        line-height: 25px;
        text-align: center;
        font-weight: 400;
        color: #333333;
        min-width: 90px;
        white-space: nowrap;
      }
    }
    .line {
      flex: 1;
      height: 1px;
      background: #d9ddeb;
      margin-top: 6px;
      min-width: 13px;
    }
  }
}

.arrow-stone .stone-content {
  flex-grow: 1;
}

.pre-stone {
  .content {
    .dot {
      background: #179eda;
      opacity: 0.5;
    }
    .name {
      font-weight: bold;
      color: #3e90fe;
    }
  }
}

// 4.9.1 里程碑控件
// 通用样式
.milepost-box {
  display: flex;
  // border: 1px #f3f3f3 solid;
  min-height: 47px;
  border-radius: 4px;
  cursor: default;
  // overflow: hidden;
  scroll-behavior: smooth; //平滑滚动
  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto;
  overflow-y: hidden;
  /* 样式2用 */
  background: #e5e9ec;
  .milepost-item {
    min-width: 80px;
    flex: 1;
    height: 47px;
    line-height: 47px;
    text-align: center;
    overflow: hidden;
    background: #f6fafd;
    .milepost-conter {
      height: 47px;
      overflow: hidden;
    }
    // 开始的空节点 最小宽度为10px
    &.milepost-empty {
      min-width: 10px !important;
    }
  }
}
// 样式1 style1 线
.milepost-box.style1 {
  // justify-content: center;
  .milepost-item {
    flex: 1;
    line-height: 0px;
    min-width: 130px;
    .milepost-conter {
      flex: 1;
      line-height: 0px;
    }
  }
  /* 尾巴 */
  .tail {
    flex: 2;
    min-width: 0px;
    line-height: 0px;
    background: #f6fafd;
  }

  .style1-top {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      flex: 1;
    }
    // 圆
    .style1-0 {
      flex: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px #acadaf solid;
    }
    // 线
    .style1-line {
      border-top: 1px #acadaf solid;
    }
  }
  .style1-txt {
    line-height: 20px;
    height: 20px;
  }

  /* 完成 */
  .milepost-item.finish {
    .style1-0 {
      border: 1px #9cc8ef solid;
    }
    .style1-txt {
      color: #a4d0ed;
    }
    .style1-line {
      border-top: 1px #9cc8ef solid;
    }
  }
  .tail.style1.finish {
    .style1-line {
      border-top: 1px #9cc8ef solid;
    }
  }
  /* 现在 */
  .milepost-item.now {
    .style1-0 {
      border: 2px #20a2de solid;
    }
    .style1-txt {
      color: #189dd8;
      font-weight: bold;
    }
    // .style1-line{
    //     border-top: 1px #179dda solid;
    // }
    .style1-line-felt {
      border-top: 1px #9cc8ef solid;
    }
  }
  .tail.style1.now {
    .style1-line {
      border-top: 1px #179dda solid;
    }
  }
  .milepost-item.now-next {
    .style1-line-felt {
      border-top: 1px #179dda solid;
    }
  }
}
// 样式2 style2 块
.milepost-box.style2 {
  .milepost-conter {
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 0;
  }
  .milepost-item {
    line-height: 14px;
    min-width: 130px;
    height: 48px;
  }
  /* 结尾箭头 */
  .tri {
    border-style: solid;
    width: 0;
    height: 0;
    border-width: 24px;
    border-style: solid;
    border-color: transparent transparent transparent #f6fafd;
  }
  /* 开始箭头 */
  .square {
    margin-left: -46px;
    border-style: solid;
    width: 0;
    height: 0;
    border-width: 24px;
    border-style: solid;
    border-color: #f6fafd #f6fafd #f6fafd transparent;
  }
  .milepost-item.finish {
    .milepost-conter {
      color: #189edd;
    }
  }

  .milepost-item.now {
    .milepost-conter {
      color: #ffffff;
      background: #189edb;
      font-weight: bold;
    }
  }

  /* 头标记 */
  .square.now {
    border-color: #189edb #189edb #189edb transparent;
  }
  .tri.now {
    border-color: transparent transparent transparent #189edb;
  }
}
// 样式3 style3 虚线
.milepost-box.style3 {
  // justify-content: center;
  .milepost-item {
    flex: 1;
    display: flex;
    justify-content: center;
    min-width: 130px;
  }
  .milepost-conter {
    display: flex;
    align-items: center;
    span {
      line-height: 14px;
    }
  }
  .style3-num {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #abacae;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 4px;
  }
  .milepost-item.finish {
    .style3-num {
      background: #9cc8ef;
    }
    .milepost-conter {
      color: #9cc8ef;
    }
  }
  .milepost-item.now {
    .style3-num {
      background: #179dda;
    }
    .milepost-conter {
      color: #179dda;
      font-weight: bold;
    }
  }

  /* 尾巴 */
  .tail {
    flex: 2;
    min-width: 12px;
    align-items: center;
    display: flex;
    background: #f6fafd;
    .tail-con {
      width: 100%;
      height: 0px;
      border-top: 2px #acadaf dashed;
    }
  }
  // 完成
  .tail.finish {
    .tail-con {
      border-top: 2px #9cc8ef dashed;
    }
  }
  // .tail.now {
  //   .tail-con {
  //     border-top: 2px #179dda dashed;
  //   }
  // }
}
</style>
